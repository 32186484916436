<template>
  <section
    id="detention"
    class="overflow-hidden"
  >
    <v-row no-gutters>
      <v-col
        class="primary text-center pa-5 white--text"
        cols="12"
        md="6"
      >
        <base-heading>
          Detention The Series<br>
          ドラマ版「返校」
        </base-heading>

        <base-text class="mb-5 text-left">
          <p>ある日、男子学生のウェイは歴史の授業中に居眠りしてしまう。目が覚めると周りには誰もおらず、黒板に台風警報とのみ書かれていた。薄暗い学校の中を彷徨いはじめたウェイは、体育館の壇上で眠る女子生徒レイを発見する。</p>
        </base-text>

        <v-card color="secondary">
          <v-container class="pa-2">
            <v-row>
              <v-col cols="12">
                <v-img :src="require(`@/assets/返校_1.jpg`)" />
              </v-col>
              <v-col cols="12">
                <v-img :src="require(`@/assets/返校_2.jpg`)" />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col
        class="hidden-sm-and-down"
        md="6"
      >
        <v-img
          :src="require('@/assets/返校_3.jpg')"
          height="100%"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    name: 'DetentionTheSeries',
  }
</script>
